import { Link } from 'react-router-dom';
import styled from 'styled-components';

import img from './thumbnail.jpg';
import logo from './logo.png';

export const Main = styled.div`
    background: #fff;
    min-height: 100%;
    display: flex;
    @media (max-width: 768px) {
        justify-content: center;
    }
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    @media (max-width: 768px) {
        max-width: 768px;
    }
    @media (min-width: 769px) {
        flex: 1;
        margin-left: -50px;
        justify-content: center;
        align-items: center;
    }
`;

export const DesktopContainer = styled.div`
    display: none;
    flex: 1;
    flex-direction: column;
    background-image: url(${img});
    background-repeat: no-repeat;
    background-size: cover;
    @media (min-width: 769px) {
        display: flex;
    }
`;

export const BorderContainer = styled.div`
    width: 50px;
    height: 100%;
    border-bottom-right-radius: 50px;
    background-color: #fff;
`;

export const ErrorContainer = styled.div`
    position: absolute;
    top: 35px;
    max-width: 270px;
    display: flex;
    flex-direction: column;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
`;

export const Error = styled.div`
    padding: 5px 10px;
    border-radius: 12px;
    justify-content: center;
    text-align: center;
    background: #e12948;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
    font-size: 16px;
    color: rgb(255, 255, 255);
    margin-bottom: 10px;
    width: 100%;
`;

export const Success = styled.div`
    padding: 5px 10px;
    border-radius: 12px;
    justify-content: center;
    text-align: center;
    background: rgb(51, 73, 91);
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
    font-size: 16px;
    color: rgb(255, 255, 255);
    position: absolute;
    top: 35px;
    max-width: 250px;
    display: flex;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
`;

export const TextHeader = styled.header`
    width: 100%;
    text-align: left;
    color: rgb(51, 73, 91);
    font-weight: 600;
    font-size: 24px;
    padding-bottom: 10px;
`;

export const TextHeaderMedium = styled.header`
    width: 100%;
    text-align: left;
    color: rgb(51, 73, 91);
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    padding-bottom: ${({ hasBigPaddingBottom }) => (hasBigPaddingBottom ? '30px' : '10px')};
`;

export const LoginTextHeader = styled(TextHeader)`
    padding-bottom: 15%;
`;

export const Body = styled.div`
    display: flex;
    flex-direction: column;
    @media (min-width: 769px) {
        max-width: 400px;
        align-items: center;
    }
`;

export const ScreenWrapper = styled.div`
    flex-direction: column;
    background-color: white;
    display: flex;
    margin-top: 20px;
    padding: 80px 20px;
    position: relative;
    @media (min-width: 769px) {
        margin-left: 50px;
        margin-top: 0;
    }
`;

export const InputWrapper = styled.div`
    width: 100%;
    padding: 0 0 5%;
    display: ${({ isInvisible }) => (isInvisible ? 'none' : 'flex')};
    flex-direction: column;
`;

export const Input = styled.input`
    border: 1px solid ${({ isError }) => (isError ? '#e12948' : '#e0e4e6')};
    border-radius: 8px;
    height: 32px;
    padding: 4px 12px;
    color: ${({ isError }) => (isError ? '#e12948' : '#5c6d7c')};
    min-width: 300px;

    @media (max-width: 350px) {
        min-width: 200px;
    }

    &:active {
        border: 1px solid rgb(52, 74, 91);
    }

    &:focus {
        border: 1px solid rgb(52, 74, 91);
        outline: none;
        color: #5c6d7c;
    }
`;

export const SubText = styled.div`
    font-weight: 400;
    color: #5c6d7c;
    padding-bottom: 30px;
    font-size: 16px;
    line-height: 20px;
    justify-text: justify;
    padding-top: 10px;
`;

export const Label = styled.label`
    font-weight: 400;
    color: ${({ isError }) => (isError ? '#e12948' : '#5c6d7c')};
    padding-bottom: 6px;
    font-size: 16px;
`;

export const Submit = styled.input`
    border-radius: 8px;
    border: none;
    height: 36px;
    width: ${({ fluidWidth }) => (fluidWidth ? 'auto' : '120px')};
    padding: ${({ fluidWidth }) => (fluidWidth ? '6px 26px' : '6px 16px')};
    background-color: ${({ isSecondary }) => (isSecondary ? 'rgb(224, 228, 230)' : 'rgb(51, 73, 91)')};
    color: ${({ isSecondary }) => (isSecondary ? 'rgb(51, 73, 91)' : 'white')};
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    margin-right: 15px;

    &:focus {
        outline: none;
    }

    &:disabled {
        background-color: rgb(224, 228, 230);
        color: rgb(51, 73, 91);
    }

    &:nth-child(2) {
        margin-top: 12px;
    }
`;

export const SubmitWrapper = styled.div`
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`;

export const ButtonsWrapper = styled.div`
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media (max-width: 768px) {
        align-items: flex-end;
    }
`;

export const BackButtonWrapper = styled.button`
    display: block;
    color: rgb(51, 73, 91);
    font-size: 14px;
    text-align: left;
    text-decoration: none;
    margin-bottom: 10px;
    padding: 0px;
    background: transparent;
    border: none;
`;

export const FormLink = styled(Link)`
    display: block;
    color: rgb(51, 73, 91);
    width: 100%;
    font-size: 14px;
    text-align: left;
    text-decoration: none;
    margin-bottom: 10px;
`;

export const ExternalLink = styled.a`
    display: block;
    color: rgb(51, 73, 91);
    width: 100%;
    font-size: 14px;
    text-align: left;
    text-decoration: none;
    margin-bottom: 10px;
`;

export const VerificationText = styled.div`
    font-weight: 400;
    color: rgb(92, 109, 124);
    padding-bottom: 60px;
`;

export const NavigationHeader = styled.div`
    margin: -60px 0 30px -12px;
`;

export const BackIcon = styled.svg`
    transform: rotate(180deg);
    color: rgb(51, 73, 91);
    width: 24px;
    height: 24px;
`;

export const Logo = styled.div`
    background-image: url(${logo});
    background-repeat: no-repeat;
    height: 34px;
    background-size: contain;
    margin-bottom: 60px;
    display: none;
    @media (min-width: 769px) {
        display: block;
    }
`;

export const StatusCircle = styled.div`
    background-color: #3de8ab;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    margin-bottom: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const CheckIcon = styled.svg`
    width: 24px;
    height: 24px;
    fill: none;
    stroke: white;
    stroke-width: 2px;
`;

export const BackIconPath = styled.path.attrs(props => ({
    d: 'M11.59 10l-4.3-4.3a1 1 0 011.42-1.4l5 5a1 1 0 010 1.4l-5 5a1 1 0 01-1.42-1.4l4.3-4.3z',
    fill: 'currentColor',
    fillRule: 'nonzero'
}))``;

export const CheckBackIconPath = styled.polyline.attrs(props => ({
    points: '3 19 16 5 21 10',
    fill: 'none',
    transform: 'translate(12.000000, 12.000000) rotate(-180.000000) translate(-12.000000, -12.000000)',
    strokeLinecap: 'round',
    strokeLinejoin: 'round'
}))``;
