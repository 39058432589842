import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { getPoolId } from '../lib/global';

import {
    ScreenWrapper,
    Submit,
    TextHeaderMedium,
    ButtonsWrapper,
    StatusCircle,
    CheckIcon,
    CheckBackIconPath,
    ErrorContainer,
    Error
} from '../styles';

const SearchParams = new URLSearchParams(window.location.search);

export default React.memo(() => {
    const history = useHistory();

    const [eventId] = React.useState(SearchParams.get('eventId'));
    const [feedbackToken] = React.useState(SearchParams.get('feedbackToken'));
    const [username] = React.useState(SearchParams.get('username'));
    const [confirmed, setConfirmed] = React.useState(false);
    const [cognitoError, setCognitoError] = React.useState(null);

    const resetError = React.useCallback(() => setCognitoError(null), [setCognitoError]);

    const setError = React.useCallback(
        error => {
            setCognitoError(error);

            setTimeout(resetError, 5000);
        },
        [setCognitoError, resetError]
    );

    const handleConfirm = React.useCallback(
        async event => {
            event.preventDefault();
            resetError();

            const params = {
                EventId: eventId,
                FeedbackToken: feedbackToken,
                FeedbackValue: 'Valid',
                UserPoolId: getPoolId(),
                Username: username
            };

            try {
                const response = await fetch('/auth/update-auth-event-feedback', {
                    method: 'POST',
                    body: JSON.stringify(params)
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                setConfirmed(true);
            } catch (error) {
                console.log(error, error.stack);
                setError('Something went wrong, please try again.');
            }
        },
        [eventId, feedbackToken, username, setError, resetError]
    );

    const goToLogin = React.useCallback(() => {
        history.push('/');
    }, [history]);

    return (
        <ScreenWrapper>
            {cognitoError && (
                <ErrorContainer>
                    <Error onClick={resetError}>{cognitoError}</Error>
                </ErrorContainer>
            )}
            {confirmed ? (
                <div>
                    <StatusCircle>
                        <CheckIcon viewBox="0 0 24 24">
                            <CheckBackIconPath />
                        </CheckIcon>
                    </StatusCircle>
                    <TextHeaderMedium>
                        <span>Thanks for letting us know</span>
                    </TextHeaderMedium>
                    <ButtonsWrapper>
                        <Submit onClick={goToLogin} type="button" value="Go to login" isSecondary fluidWidth />
                    </ButtonsWrapper>
                </div>
            ) : (
                <div>
                    <TextHeaderMedium>
                        <span>Please confirm this login attempt was from you.</span>
                    </TextHeaderMedium>
                    <ButtonsWrapper>
                        <Submit onClick={handleConfirm} type="button" value="It was me" fluidWidth />
                    </ButtonsWrapper>
                </div>
            )}
        </ScreenWrapper>
    );
});
