import * as React from 'react';
import url from 'url';
import { withRouter } from 'react-router';
import { useHistory, Redirect } from 'react-router-dom';
import { AuthenticationHelper } from 'amazon-cognito-identity-js';

import { createLargeAValue, createChallengeResponses, getUserContextData } from '../lib/auth';
import BigInteger from 'amazon-cognito-identity-js/es/BigInteger';
import { changeHandler } from '../lib/form';
import { getClientId, getRedirectURL, getPoolId, getCognitoClientId } from '../lib/global';
import {
    ScreenWrapper,
    InputWrapper,
    ErrorContainer,
    Error,
    Success,
    Input,
    Submit,
    TextHeader,
    SubmitWrapper,
    VerificationText,
    NavigationHeader,
    BackIcon,
    BackIconPath,
    BackButtonWrapper
} from '../styles';

// Time in ms before the resend button is enabled
const ALLOW_RESEND_DELAY = 30 * 1000;

// Time in ms to show resend success for
const SHOW_RESEND_SUCCESS_WINDOW = 10 * 1000;

export default withRouter(({ clientId, location }) => {
    // Redirect users who end up visiting the verify url
    // without coming from the login form
    if (!location.state) {
        return <Redirect to="/" />;
    }

    const history = useHistory();
    const { ChallengeParameters, Session, ChallengeName, USERNAME, username, password } = location.state;

    const [code, setCode] = React.useState('');
    const [resendEnabled, setAllowResend] = React.useState(false);
    const [verifyError, setVerifyError] = React.useState(null);
    const [inputDisabled, setInputDisabled] = React.useState(false);

    const [showResendSuccess, setShowResendSuccess] = React.useState(false);
    // eslint-disable-next-line no-unused-vars
    const [resendEnableTimer, setResendEnableTimer] = React.useState(undefined);
    // eslint-disable-next-line no-unused-vars
    const [hideResendSuccessTimer, setHideResendSuccessTimer] = React.useState(undefined);

    const handleChange = changeHandler({ code: setCode });

    // Disable resend button for X seconds until after 1st page render
    React.useEffect(() => {
        startResendEnableTimer();
        return clearResendEnableTimer;
    }, []);

    // start the timer for enabling the resend button, but only if no existing timer
    function startResendEnableTimer() {
        setResendEnableTimer(prev => {
            return prev
                ? prev
                : setTimeout(() => {
                      setAllowResend(true);
                      setResendEnableTimer(undefined);
                  }, ALLOW_RESEND_DELAY);
        });
    }

    // clear any existing time for the enabling the resend button
    function clearResendEnableTimer() {
        setResendEnableTimer(clearTimeout);
    }

    // Tidy up any timers left over on unmounting
    React.useEffect(() => clearHideResendSuccessTimer, []);

    // start the time for hiding the resend success message
    // but only if there is no existing timer
    function startHideResendSuccessTimer() {
        setHideResendSuccessTimer(prev => {
            return prev
                ? prev
                : setTimeout(() => {
                      setShowResendSuccess(false);
                      setHideResendSuccessTimer(undefined);
                  }, SHOW_RESEND_SUCCESS_WINDOW);
        });
    }

    // clear any existing time for the hiding the reesend success message
    function clearHideResendSuccessTimer() {
        setHideResendSuccessTimer(clearTimeout);
    }

    const hideResendSuccess = () => {
        setShowResendSuccess(false);
    };

    const handleResend = async event => {
        event.preventDefault();

        setAllowResend(false);
        setCode('');
        resetError();

        const client = getClientId();
        const userPoolId = getPoolId();
        const cognitoClientId = getCognitoClientId();

        try {
            // Yes - this is a copy paste of login.js mostly
            const initiateUrl = url.format({ pathname: '/auth/login', query: { client } });

            const authenticationHelper = new AuthenticationHelper(userPoolId.split('_')[1]);

            const initRes = await fetch(initiateUrl, {
                method: 'POST',
                body: JSON.stringify({
                    AuthParameters: {
                        USERNAME: username,
                        SRP_A: await createLargeAValue(authenticationHelper)
                    },
                    UserContextData: getUserContextData(username, userPoolId, cognitoClientId)
                })
            });

            const { ChallengeParameters, Session, ChallengeName } = await initRes.json();

            const ChallengeResponses = await createChallengeResponses(
                BigInteger,
                authenticationHelper,
                password,
                ChallengeParameters
            );

            // TODO: move redirect param to later requests
            const challengeRes = await fetch(initiateUrl, {
                method: 'POST',
                body: JSON.stringify({
                    ChallengeResponses,
                    ChallengeName,
                    Session,
                    UserContextData: getUserContextData(username, userPoolId, cognitoClientId),
                    redirect: getRedirectURL()
                })
            });

            const body = await challengeRes.json();

            // Should be only scenario we care about - treat anything else as an error.
            // MFA Challenge - send the user to the verify screen
            if (body.ChallengeName === 'SMS_MFA' || body.ChallengeName === 'SOFTWARE_TOKEN_MFA') {
                setShowResendSuccess(true);
                startHideResendSuccessTimer();
                startResendEnableTimer();
                history.push(`/verify${window.location.search}`, {
                    ChallengeParameters: body.ChallengeParameters,
                    Session: body.Session,
                    ChallengeName: body.ChallengeName,
                    USERNAME: body.USERNAME,
                    username,
                    password
                });
                return;
            }
            setVerifyError('An unexpected error occurred. Please try again');
            setAllowResend(true);
        } catch (e) {
            setVerifyError('An unexpected error occurred. Please try again');
            setAllowResend(true);
        }
    };

    const handleSubmit = async event => {
        event.preventDefault();

        setAllowResend(false);
        resetError();
        setInputDisabled(true);

        const client = getClientId();

        try {
            const verifyUrl = url.format({ pathname: '/auth/login', query: { client } });

            const res = await fetch(verifyUrl, {
                method: 'POST',
                body: JSON.stringify({
                    ChallengeName,
                    Session,
                    ChallengeResponses: {
                        [`${ChallengeName}_CODE`]: code,
                        USERNAME: USERNAME
                    },
                    redirect: getRedirectURL()
                })
            });

            const body = await res.json();

            // All is well - send the user to the client site
            if (res.status === 200) {
                window.location.href = body.location;
                return;
            }

            // Verification code incorrect or expired
            if (res.status === 401) {
                setVerifyError('Verification code error. Please try again');
                setCode('');
                return;
            }

            // Incorrect device ID submitted
            if (res.status === 400 && body.error === 'DEVICE_NOT_FOUND') {
                window.location.href = body.location;
                return;
            }

            // Something else went wrong
            setVerifyError('An unexpected error occurred. Please try again');
        } catch (err) {
            setVerifyError('An unexpected error occurred. Please try again');
        } finally {
            setAllowResend(true);
            setInputDisabled(false);
        }
    };

    const resetError = () => setVerifyError(null);

    let verificationText;

    if (ChallengeName === 'SMS_MFA') {
        verificationText = (
            <VerificationText>
                <span>A verification code has been sent to "{ChallengeParameters.CODE_DELIVERY_DESTINATION}"</span>
                <br />
                <span>If it does not arrive within 30 seconds you can request a new code.</span>
            </VerificationText>
        );
    } else if (ChallengeName === 'SOFTWARE_TOKEN_MFA') {
        verificationText = (
            <VerificationText>
                <span>
                    Enter the verification code displayed on your authenticator app "
                    {ChallengeParameters.FRIENDLY_DEVICE_NAME}"
                </span>
            </VerificationText>
        );
    } else {
        verificationText = (
            <VerificationText>
                <span>Enter the verification code</span>
            </VerificationText>
        );
    }

    return (
        <ScreenWrapper>
            {verifyError && (
                <ErrorContainer>
                    <Error onClick={resetError}>{verifyError}</Error>
                </ErrorContainer>
            )}
            {showResendSuccess && <Success onClick={hideResendSuccess}>New verification code sent</Success>}
            <NavigationHeader>
                <BackButtonWrapper onClick={() => history.goBack()}>
                    <BackIcon viewBox="0 0 20 20">
                        <BackIconPath />
                    </BackIcon>
                </BackButtonWrapper>
            </NavigationHeader>
            <TextHeader>
                <span>Verification code</span>
            </TextHeader>
            {verificationText}
            <form onSubmit={handleSubmit}>
                {verifyError && (
                    <ErrorContainer>
                        <Error>{verifyError}</Error>
                    </ErrorContainer>
                )}
                <InputWrapper onClick={hideResendSuccess}>
                    <Input
                        name="code"
                        value={code}
                        onChange={handleChange}
                        required="true"
                        type="password"
                        minLength="6"
                        maxLength="6"
                        disabled={inputDisabled}
                    />
                </InputWrapper>
                <SubmitWrapper>
                    <Submit disabled={code.length < 6 || inputDisabled} type="submit" value="Verify" />
                    {ChallengeName === 'SMS_MFA' && (
                        <Submit
                            disabled={!resendEnabled || code.length === 6}
                            onClick={handleResend}
                            type="submit"
                            value="Resend"
                        />
                    )}
                </SubmitWrapper>
            </form>
        </ScreenWrapper>
    );
});
