export const getRedirectURL = () => {
    const url = new URL(window.location.href);
    return url.searchParams.get('redirect');
};

export const getPoolId = () => {
    if (!window.HiveSSOPoolId.match(/^%%(.*)%%$/)) {
        return window.HiveSSOPoolId;
    }
};

export const getCognitoClientId = () => {
    if (!window.HiveSSOCognitoClientId.match(/^%%(.*)%%$/)) {
        return window.HiveSSOCognitoClientId;
    }
};

export const getPublicCognitoClientId = () => {
    if (!window.HiveSSOPublicCognitoClientId.match(/^%%(.*)%%$/)) {
        return window.HiveSSOPublicCognitoClientId;
    }
};

export const getClientId = () => {
    const url = new URL(window.location.href);
    return url.searchParams.get('client');
};
