export const changeHandler = handlers => event => {
    event.preventDefault();
    const { name, value } = event.target;
    handlers[name] && handlers[name](value);
};

export const validationHandler = handlers => event => {
    event.preventDefault();
    const { name } = event.target;
    handlers[name] && handlers[name](true);
};

export const validationResetHandler = handlers => event => {
    event.preventDefault();
    const { name } = event.target;
    handlers[name] && handlers[name](false);
};
